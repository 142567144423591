export default {
    methods: {
        sanitizeTitle(title) {
            var str = title.toLowerCase();
            str = str.trimStart();
            str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
            str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
            str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
            str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
            str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
            str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
            str = str.replace(/đ/g, "d");
            // Some system encode vietnamese combining accent as individual utf-8 characters
            str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
            str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
            // Trim the last whitespace
            str = str.replace(/\s*$/g, '');
            str = str.replace(/\?|\(|\)|\/|!|@|#|\$|%|\^|&|:|,|\||\]|\[|{|}|\\|-|/g,'');
            // Change whitespace to "-"
            str = str.replace(/\s+/g, '-');

            return str;
        },
        validateImage(fileInput) {
            let obj ={
                result:false,
                message:""
            }
            var filePath = fileInput.value;
            if(!filePath){
                obj.message = "Ảnh không được để trống"
                return obj;
            }
            // Allowing file type
            var allowedExtensions =
                /(\.jpg|.png|.jpeg)$/i;
            if (!allowedExtensions.exec(filePath)) {
                fileInput.value = '';
                obj.message = "Ảnh không đúng định dạng!"
                return obj;
            }
            obj.result = true;
            return obj;
        },
    }
}
